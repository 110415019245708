import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

export const DropBoxHeader = ({ darkMode, toggleMode }) => {
    return (
        <div className={darkMode ? 'w-100 bg-dark fixed-top' : 'w-100 side-nav fixed-top'}>
            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                <div className="d-flex flex-row">
                    <div className="mr-2" style={{ 'height': '1rem', 'width': '1rem', 'borderRadius': '100%', 'backgroundColor': 'red' }}></div>
                    <div className="mr-2" style={{ 'height': '1rem', 'width': '1rem', 'borderRadius': '100%', 'backgroundColor': 'yellow' }}></div>
                    <div style={{ 'height': '1rem', 'width': '1rem', 'borderRadius': '100%', 'backgroundColor': 'green' }}></div>
                </div>
                <div className="d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={faSun} className="mr-2" />
                    <div className="custom-control custom-switch" onChange={() => toggleMode(darkMode)}>
                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                        <label className="custom-control-label" htmlFor="customSwitch1" />
                    </div>
                    <FontAwesomeIcon icon={faMoon} />
                </div>
            </div>
        </div>
    )
}