import React from 'react';
import { get } from 'lodash';
import { OpenFolder } from './open-folder';


export const FileView = ({ projectInfo = {} }) => {
    const data = get(projectInfo, 'contents.fields.data').map(content => content.fields);
    return (
        <>
            {/* {data && data.map(item => <OpenFolder key={item.id} images={images} icon={item.icon} url={item.url} title={item.title} img={item.img} folder={data} />)} */}
            {data && data.map(item => <OpenFolder key={item.url || item.title} title={item.title} icon={item.icon} url={item.url} img={item.img && item.img.fields.file.url} imgName={item.img && item.img.fields.file.fileName} />)}
        </>
    )
};
