import { createSelector } from 'reselect';

const getStoreState = ({ entries = {} }) => entries;

export const getAllEntries = createSelector(
    getStoreState,
    ({ allEntries }) => allEntries
);

export const isLoading = createSelector(
    getStoreState,
    ({ loading }) => loading
)