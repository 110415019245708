import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import '../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import '../scss/App.scss';


export const Image = ({ images }) => {
    const filteredImages = images.filter((img) => img !== null);
    return (
        <div>
            <Carousel showThumbs={false} swipeable={true} dynamicHeight={false}>
                {filteredImages.length <= 1 ? <div><img src={filteredImages} /></div>
                    : filteredImages.map(
                        img => <div>
                            <img src={img} />
                        </div>
                    )}
            </Carousel>
        </div>
    )
};
