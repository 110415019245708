import { call, put } from 'redux-saga/effects';
import { getAllEntries } from '../../services/entries-service';
import { setEntries, setLoading } from './actions';

export const onGetAllEntries = function* ({ payload }) {
    yield put(setLoading(true))
    try {
        const response = yield call(getAllEntries, payload);
        const site = response.data.items.map(item => item.fields).find(field => field.siteName === 'Portfolio Site');
    yield put(setEntries(site.theflyblkgrl))
    yield put(setLoading(false))
    } catch(e) {
        console.log(e);
    }
    
}