import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faClock, faHome, faTrash, faPlusCircle, faMinusCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { useHistory } from 'react-router-dom';

export const SideNav = () => {
    const [showMore, setShowMore] = useState(true);
    const openDrawer = () => {
        setShowMore(!showMore);
    }
    const history = useHistory();
    return (
        <div style={{ paddingTop: '4.5rem' }}>
            <div onClick={() => history.push('/')} className="d-flex flex-row justify-content-start align-items-center mb-4 custom-cursor">
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                <p className="m-0 d-none d-sm-block">Home</p>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                <FontAwesomeIcon icon={faFolder} className="mr-2" />
                <p className="m-0 d-none d-sm-block">All Files</p>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                <p className="m-0 d-none d-sm-block">Recent</p>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                <p className="m-0 d-none d-sm-block">Trash</p>
            </div>
            <div onClick={() => openDrawer()} className="d-flex flex-row justify-content-start align-items-center mb-4 custom-cursor">
                {showMore ?
                    <FontAwesomeIcon icon={faMinusCircle} className="mr-2" />
                    : <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                }
                <p className="m-0 d-none d-sm-block">Favorites</p>
            </div>
            {showMore &&
                <div>
                    <a href="https://www.linkedin.com/in/brittanytjohnson" target="_blank" className="outside-links">
                        <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                            <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                            <p className="m-0 d-none d-sm-block">LinkedIn</p>
                        </div>
                    </a>
                    <a href="https://github.com/brittjohn87" target="_blank" className="outside-links">
                        <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                            <FontAwesomeIcon icon={faGithub} className="mr-2" />
                            <p className="m-0 d-none d-sm-block">Github</p>
                        </div>
                    </a>
                    <a href="mailto:brittany@blkwmn.tech?subject=Let's Chat!" target="_blank" className="outside-links">
                        <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                            <p className="m-0 d-none d-sm-block">Email</p>
                        </div>
                    </a>
                </div>
            }
        </div >
    )
};
