export const getEntries = () => ({
    type: 'entries/GET_ENTRIES',
    payload: {}
});

export const setEntries = (payload) => ({
    type: 'entries/SET_ENTRIES',
    payload
});

export const setLoading = (payload) => ({
    type: 'entries/SET_LOADING',
    payload
});