import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export const OpenFolder = ({
  icon,
  url,
  title,
  img,
  imgName
}) => {
  const history = useHistory();
  return (
    <>
      <div className="d-flex flex-row align-items-center mb-3">
        <div className="col-6 d-none d-md-block">
          <div className="d-flex align-items-center custom-cursor">
            <div className="col-3">
              {icon === 'faLink' ? (
                <FontAwesomeIcon icon={faLink} />
              ) : (
                  <img
                    style={{ height: '40px' }}
                    src={img}
                    onClick={() => history.push('/carousel')}
                  />
                )}
            </div>
            <div className="col-9">
              {url ? (
                <a href={url} target="_blank" className="outside-links">
                  {title}
                </a>
              ) : (
                  imgName
                )}
            </div>
          </div>
        </div>
        <div className="col-12 d-md-none">
          <div className="d-flex align-items-center">
            <div className="col-4">
              {icon === 'faLink' ? (
                <FontAwesomeIcon icon={faLink} className="mr-2" />
              ) : (
                  <img
                    style={{ height: '40px' }}
                    src={img}
                    className="mr-2"
                    onClick={() => history.push('/carousel')}
                  />
                )}
            </div>
            <div className="col-8">
              {url ? (
                <a href={url} target="_blank" className="outside-links">
                  {title}
                </a>
              ) : (
                  imgName
                )}
            </div>
          </div>
        </div>
        <div className="col-3 d-none d-sm-block">--</div>
        <div className="col-3 d-none d-sm-block">Only You</div>
      </div>
      <hr />
    </>
  );
};