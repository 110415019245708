const initialState = {
    allEntries: [],
    loading: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case 'entries/SET_ENTRIES':
            return {
                ...state,
                allEntries: action.payload
            }
        case 'entries/SET_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
};
