import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './ducks/root/reducers';
import rootSaga from './ducks/root/sagas';
import createSagaMiddleware from 'redux-saga';
import './scss/index.scss';
import App from './components/App.js';
import * as serviceWorker from './serviceWorker';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer(),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

const Root = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
