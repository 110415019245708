import React, { useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import DropBox from '../containers/drop-box';

// Styles //
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../scss/App.scss'


const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleMode = (darkMode) => {
    setDarkMode(!darkMode);
  }

  return (
    <div className={darkMode ? 'bg-dark text-white' : 'bg-white text-black'}>
      <MemoryRouter>
        <DropBox darkMode={darkMode} toggleMode={toggleMode} />
      </MemoryRouter>
    </div>
  )
}

export default App;
