import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFolder } from '@fortawesome/free-solid-svg-icons';

const Files = ({ getFileId, fileName, type, contents }) => {
  const history = useHistory();
  const icon = type === 'folder' ? faFolder : faFilePdf;
  const findResume =
    contents &&
    contents.fields.data
      .map((x) => x.fields)
      .find((x) => x.title === 'resume_2020.pdf');
  const resume = findResume && findResume.img.fields.file.url;
  return (
    <div onClick={() => getFileId(fileName)}>
      <div
        onClick={type === 'file' ? null : () => history.push('/file-view')}
        className="d-flex flex-row align-items-center mb-3"
      >
        <div className="col-6 d-none d-md-block custom-cursor">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={icon}
              color="#92cefe"
              size="2x"
              className="mr-2"
            />
            {type === 'file' ? (
              <a
                href={resume}
                target="_blank"
                download
                className="outside-links"
              >
                <span>{fileName}</span>
              </a>
            ) : (
              <span>{fileName}</span>
            )}
          </div>
        </div>
        <div className="col-12 d-md-none">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={icon}
              color="#92cefe"
              size="2x"
              className="mr-2"
            />
            {type === 'file' ? (
              <a
                href={resume}
                target="_blank"
                download
                className="outside-links"
              >
                <span>{fileName}</span>
              </a>
            ) : (
              <span>{fileName}</span>
            )}
          </div>
        </div>
        <div className="col-3 d-none d-sm-block">--</div>
        <div className="col-3 d-none d-sm-block">Only YOU</div>
      </div>
      <hr />
    </div>
  );
};

export default Files;
