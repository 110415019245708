import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, Link } from "react-router-dom";
import { getEntries } from "../ducks/entries/actions";
import { getAllEntries, isLoading } from "../ducks/entries/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Files from "../components/files";
import { DropBoxHeader } from "../components/drop-box-header";
import { FileView } from "../components/file-view";
import { SideNav } from "../components/side-nav";
import { Image } from "../components/image";

const DropBox = ({ darkMode, toggleMode }) => {
  const [fileId, setFileId] = useState();
  const [showOverview, setShowOverview] = useState(true);

  const dispatch = useDispatch();
  const entries = useSelector(getAllEntries);
  const loading = useSelector(isLoading);
  useEffect(() => {
    dispatch(getEntries());
  }, []);

  const getFileId = (name) => {
    setFileId(name);
  };
  const fileContents = entries
    .map((entry) => entry.fields)
    .map((item) => ({
      name: item.name,
      type: item.type,
      contents: item.folderContent,
    }));
  const fileStructure = fileContents.map((info) => (
    <Files
      key={info.name}
      getFileId={getFileId}
      type={info.type}
      fileName={info.name}
      contents={info.contents}
    />
  ));
  const location = useLocation();
  const infoToShow = fileContents.find((file) => file.name === fileId);
  const images =
    infoToShow &&
    infoToShow.contents.fields.data
      .map((info) => info.fields)
      .filter((image) => image.img)
      .map((x) => x.img.fields.file.url);
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <DropBoxHeader darkMode={darkMode} toggleMode={toggleMode} />
      <div className="d-flex flex-row w-100">
        <div
          className={
            darkMode
              ? "col-2 bg-dark d-flex justify-content-center pt-5 fixed-nav"
              : "col-2 d-flex justify-content-center side-nav pt-5 fixed-nav"
          }
        >
          <SideNav darkMode={darkMode} />
        </div>
        <div
          className={
            location.pathname === "/file-view"
              ? "col-10 pt-2 pb-2"
              : "col-10 pt-2 pb-2"
          }
          style={{ left: "16.6%", height: "100vh", marginTop: "56px" }}
        >
          <div className="inner-padding h-100">
            <div className="d-flex flex-row align-items-center">
              <Link to="/" className="pt-2 pb-4 link-style">
                theflyblkgrl
              </Link>
              <Link
                to="/file-view"
                className="pl-1 pt-2 pb-4 font-weight-bold link-style"
              >
                {location.pathname !== "/" ? (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faChevronRight} /> {infoToShow.name}
                  </>
                ) : null}
              </Link>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <h5>Overview</h5>
              <p
                className="m-0 custom-cursor"
                onClick={() => setShowOverview(!showOverview)}
              >
                {showOverview ? "Hide" : "Show"}
              </p>
            </div>
            <hr
              className={showOverview ? "pl-3 mt-1 mb-1" : "pl-3 mt-1 mb-5"}
            />
            {showOverview && (
              <p
                className={
                  darkMode
                    ? "pt-3 pb-3 m-0 overview-text"
                    : "pt-3 pb-3 text-muted overview-text m-0"
                }
              >
                Welcome! I’m delighted to have you here and to provide you a
                more “organized” view of my life. Being interested in all things
                tech & media, I constantly find myself exploring the many
                mediums of the field. Here you’ll have the ability to learn more
                about me as a Software Engineer, Photographer, and DJ/Selector.
                Let's collaborate soon!
              </p>
            )}
            <Route
              exact
              path="/"
              render={() => (
                <>
                  <div className="d-flex flex-row">
                    <div className="col-6 d-none d-sm-block">
                      <p className="m-0">Name</p>
                    </div>
                    <div className="col-3 d-none d-sm-block">
                      <p className="m-0">Modified</p>
                    </div>
                    <div className="col-3 d-none d-sm-block">
                      <p className="m-0">Members</p>
                    </div>
                  </div>
                  <hr className="mt-1 mb-3 d-none d-sm-block" />
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className={
                          darkMode
                            ? "spinner-border text-light"
                            : "spinner-border text-dark"
                        }
                      ></div>
                    </div>
                  )}
                  <div
                    className={
                      showOverview ? "folder-scroll" : "folder-scroll-overview"
                    }
                  >
                    {fileStructure}
                  </div>
                </>
              )}
            />

            <Route
              path="/file-view"
              render={() => (
                <>
                  <div className="d-flex flex-row">
                    <div className="col-6 d-none d-sm-block">
                      <p className="m-0">Name</p>
                    </div>
                    <div className="col-3 d-none d-sm-block">
                      <p className="m-0">Modified</p>
                    </div>
                    <div className="col-3 d-none d-sm-block">
                      <p className="m-0">Members</p>
                    </div>
                  </div>
                  <hr className="mt-1 mb-3 d-none d-sm-block" />
                  <div
                    className={
                      showOverview ? "folder-scroll" : "folder-scroll-overview"
                    }
                  >
                    <FileView projectInfo={infoToShow} />
                  </div>
                </>
              )}
            />
            <Route path="/carousel" render={() => <Image images={images} />} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropBox;
